	/*----- mediaqueries -----------------------------------------*/
@media all and ( max-width: 1024px ) {
	// GLOBAL
	h2 {
		font-size: 2.6rem;
	}
	
	//NAVIGATION OPEN ICON
	.menu {		
		&::before,
		&::after {
			content: initial;
		}
		
		a {
			background-image: url("/site/images/open_white.png");
			background-repeat: no-repeat;
		}
	}
	
	.close {
		padding: 7px 9px;
	}
	
	.main-navigation {
		padding: 15px 0 0 0;
		
		nav {
			margin-top: 15px;
		}
	}
	
	.g {
		padding: 7px 11px;
	}
	
	//LANDINGPAGE
	#fullpage {
		.text-area {
			h2 {
				font-size: 2.2rem;
			}
		}
	}
	
	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 4.3%;
			}
		} 	
	}

	
	#section3 {
		article {
			margin-top: 100px;
			
			h2 {
				font-size: 2.2rem;
			}
		}
	}
	
	.opacityThird {
		opacity: 0.3;
		transform: scale(1);
	}
	
	//HEADER
	#page-header {
		height: 60px;
	}
	
	#logo img {
		width: 80%;
	}

	.social-media-wrapper-div {
		left: 19px;
		width: 34px;
		height: 152px;
		padding: 4px 0;

		a {
			padding: 8px 5px;

			&:hover {
				transform: scale(1.15);
			}
		}
	}

	.teaser {
		.teaser-headline {
			h1 {
				width: 100%;
			}
		}
	}
	
	//CONTENT
	.view-overview-big {
		a {			
			img {
				.opacityThird;
			}
		}
	}
	
	.view-overview-small {
		a {			
			img {
				.opacityThird;
			}
			
			.detail-link-title {
				visibility: visible;
				opacity: 1;
				font-size: @fontSizeNormal;
			}
		}
	}
		
	.orangebox {
		height: 300px;
		margin: 0;
				
		.department-title {
			//padding-top: 0;
		}
	}
	
	.team-item {
		height: 300px;
		margin: 0;
		
		span, a {
			font-size: @formPlaceholder;
		}
		
		a {
			z-index: 11;
		}		
				
		.portrait-image {
			.opacityThird;
		}
		
		.mail {
			.mail-icon {
				opacity: 1
			}
		}
	}

	.detail-link-title {
		max-width: 100%;
	}

} // end 1020px

@media all and ( max-width: 950px ) {
	.social-media-wrapper-div {
		top: 14px;
		right: 70px;
		left: auto;
		width: 176px;
		height: 36px;
		padding: 0;
		z-index: 100;
		background: none;

		&:hover {
			background: none;
		}

		a {
			padding: 8px 12px;
			width: auto;
			text-align: center;
			display: inline-block;
			line-height: 0;
			transition: all 0.2s ease;
			cursor: pointer;
			opacity: 0.8;

			img {
				height: 20px;
				width: auto;
			}

			&:hover {
				transform: scale(1.2);
				opacity: 1.0;
			}
		}
	}
}

@media all and ( max-width: 800px ) {
	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 4%;
			}
		} 	
	}
	
	.content {
		padding: 0 10px;
	}
	.nav-article {
		padding: 0 10px;
	}
	
	.detail-link-title {
		font-size: 2.4rem;
	}
} // end 800px

@media all and ( max-width: 768px ) {
	#section0 {
		&.fp-completely {
			h1 {
				width: 90%;
			}
		}
	}

	#section1 {
		.text-area {
			h2 {
				padding: 0 60px;
			}
		}
	}
	
	.main-navigation {
		//width: 100%;
		transition: all .3s ease-in;
	}
	
	.lang-nav {
		display: none;
		
		// Mobile Lang Nav
		&.mobile {
			//display: block;
			width: 70%;
		}
	}
	
	#fullpage {
		.view-list {
			padding: 0 70px;
		}
	}
	
	.view-list {
		//padding: 0 10px;
	}
	
	.text-area {
		max-width: 580px;
		
		&.job-description {
			max-width: 100%;
		}
	}
	
} // end 767px

@media all and ( max-width: 750px ) {
	
	#section0 {
		&.fp-completely {
			h1 {
				width: 100%;
			}
		}
	}
	
	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 6%;
			}
		} 	
	}
	
	.fp-controlArrow {
		&.fp-next,
		&.fp-prev {
			right: 14%;
			background-repeat: no-repeat;
			background-size: 70%;
		}
	}
	#fp-nav {
		display: none;
	}
	
	h1 {
		font-size: 3rem;
	}
	
	.content-footer {
		.content-footer-title {
			font-size: 3rem;
		}
	}

	
	#newsblock {
		.news {
			span {
				&.icon {
					width: 10%; 
				}
			}
			
			h2 {
				width: 90%;
			}
		}
	}
	
	#btnMore {
		margin: 20px 0;
	}
} // end 750px

@media all and ( max-width: 640px) {
	
	// LANDINGPAGE
	#section0,
	#section1,
	#section2,
	#section3,
	#section4
	#section5 {
		h1 {
			max-width: 300px;
		}	
	}
	
	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 7.5%;
			}
		} 	
	}
	
	//TEASER
	.teaser {
		height: 220px;
		
		.teaser-headline {
			top: 35%;
			
			h1 {
				top: 100px;
			}
		}
		
		.header-image {
			height: 220px;
			background-position: center center;
		}
	}
	
	// NAVIGATTION
	.main-navigation {
		nav {
			.nav-first-level {
				li {
					padding: 15px 0
				}
			}
		}
		
		a {
			font-size: @fontSizeNormal;
		}
	}

	// NEWS
	#newsblock {
		li {
			a {
				padding: 0;
				
				h2 {
					
				}
			}
		}
	}
	
	#logo { 
		a {
			width: 248px;
			height: 100%;
			margin: 2px 0 0 0px;
		}
	}
	
	
	.close {
		padding: 2px 15px;
	}
	
	.icon.menu-close {
		margin-right: 10px;
	}
	
	// BOX WRAPPER
	.box-wrapper {
		width: 100%;
		padding: 5px 0;
		
		a {
			width: 100%;
		}
	}
	
	
	.detail-link-title {
		max-width: 100%;
		font-size: 2.4rem;
	}
	
		
	.link-wrapper {
		a {
			max-width: 100%;
		}
	}
	
	.orangebox,
	.team-item {
		max-width: initial;
		width: 100%;
	}
	
	.team-item {
		.mail {
			margin-top: 25px;
		}
	}
	
	.orangebox {
		.department-title {
			max-width: initial;
			width: 100%;
		}
	}
	
	// ARTICLE PAGINATION
	.nav-article-back {
		width: 40%;
	}
	.nav-article-prev,
	.nav-article-next {
		width: 30%;
	}
	
	
	// CONTENT SWIPER
	.swiper-container {
		width: 100%    !important;
		height: auto   ;
		
		iframe {
			height: 413px;
		}
	}
	
} // end 640px

@media all and ( max-width: 481px ) {
	
	//LANDINGPAGE
	#section1,
	#section2 {
		p {
			bottom: 60px;
		}
		
		h2 {
			bottom: 120px;
		}
		
		.text-area {
			h2 {
				padding: 0 10px;
				font-size: 2.2rem;
			}
		}
	}
	
	#section3 {
		.fp-controlArrow {
			&.fp-prev,
			&.fp-next {
				//display: none;
			}
		}
	}
	

	#section4 {
		#newsblock  {
			.news  {
				h2 {
					padding: 12px 10px 12px 0px;
				}
				span {
					margin-top: 10px;
				}
			}
		}
	}
	
	
	#section5 a[href^=tel], 
	#section5 p, 
	.content-footer a[href^=tel], 
	.content-footer p {
		margin-top: 0;
	}
	
	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 9.5%;
			}
		} 	
	}
	
	.fp-controlArrow {
		&.fp-prev {
			left: 4%;
		}
		
		&.fp-next {
			right: 4%;
		}
	}
	
	#fullpage {
		.view-list {
			padding: 0 30px;
		}
	}


	// NAVIGATION
	.menu {
		margin: 17px 22px 0 0;
	}
	
	#logo { 
		a {
			width: 210px;
			margin: 5px 0 0;
		}
	}
	
	.main-navigation {
		width: 100%;
	}
	
	// CONTENT
	.text-area {
		padding: 30px 0 0 0;
	}
	
	.view-overview-big,
	.view-overview-small {
		margin-top: 25px;
	}
	
	.content-detail {
		padding: 30px 0 20px;
		
		iframe {
			height: auto;
		}
	}
	
	// CONTENT SWIPER
	.swiper-container {
		width: 100%    !important;
		height: auto   ;
		
		iframe {
			height: 306px;
		}
	}

	
	// FOOTER
	.footer-wrapper-disclaimer {
		display: none;
	}

	.social-media-wrapper-div {
		right: 54px;
		width: 100px;

		a {
			padding: 8px 4px;

			img {
				height: 16px;
			}
		}
	}
	
} // end 481px

@media all and ( max-width: 361px ) {
	// LANDINGPAGE

	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 13%;
			}
		} 	
	}
	
	#section5 {
		padding-top: 40px;
		
		.fp-tableCell {
			height: auto !important;
		}
		
		.text-area {
			display: none;
			padding-top: 55px;
		}
		
		.social-media-wrapper {
			margin-bottom: 10px;
		}
	}
	
	#newsblock {
		.news {
			h2 {
				font-size: 1.5rem;
			}
		}
	}
	
	// MAGNIFIC POPUP
	.mfp-content {
		form {
			select {
				width: 100%;
			}
		}
		
		.contact-form,
		.newsletter-form,
		.freelancer-form {
			h1 {
				margin: 55px 0 35px;
				font-size: 2.4rem;
			}
		}
		
		.newsletter-form {
			margin: 0 40px;
		}
	}
	
	// XFORM
	 #rex-yform {
		form {
			margin: 0 40px;
			
			select {
				width: 100%;
			}
		}
	}
	
	
	// CONTENT SWIPER
	.swiper-container {
		width: 100%    !important;
		height: auto   ;
		
		iframe {
			height: 227px;
		}
	}
	
} // end 361px

@media all and ( max-width: 320px ) {
	#slide1,
	#slide2,
	#slide3 {
		.fp-tableCell {
			h1 {
				margin: 0 auto 14%;
			}
		} 	
	}
	
	#section5 {
		p {
			&:first-child {
				margin-top: 60px !important;
			}
		}
	}

	// CONTENT SWIPER
	.swiper-container {
		width: 100% !important;
		height: auto;
		
		iframe {
			height: 200px;
		}
	}
} // end 320px	


// HEIGHT MQ IPHONE 4 AND LOWER FIX
@media all and ( max-height: 400px ) {
	
	.main-navigation {
		nav {
			.nav-first-level {
				li {
					padding: 7.5px 0 !important;
				}
			}
		} 
	}
	
	#section0 {
		.text-area {
			margin-top: 30%;
		}
	}
	
	#section1 {
		.text-area {
			margin-top: 30%;
			
			h2 {
				font-size: 1.8rem;
				padding: 0 15px;
			}
		}
	}
	
	#section2 {
		.text-area {
			margin-top: 22%;
			
			h2 {
				font-size: 1.8rem;
				padding: 0 15px;
			}
		}
	}
	
	#section3 {
		h1 {
			margin-top: 47%;
		}
		
		article {
			margin-top: 0;
			
			h2 {
				font-size: 1.8rem;
			}
		}
		.icon-arrow-down {
			background-position: 0;
		}
	}
	
	#section4 {
		.text-area {
			padding: 60px 0 10px 0;
		}
		
		.icon-wrapper {
			.icon-arrow-down {
				bottom: 0;
			}
		}

		.icon-arrow-down {
			background-position: 0;
		}
	}
	
	#section5 {
		padding: 0;
		
		.footer-contet-wrapper {
			p {
				font-size: 1.6rem;
				margin: 5px 0;
			}
			
			a[href^=tel],
			a[href^=tel] {
				margin-top: 5px;
				font-size: 1.6rem;
			}
		}
		
		.social-media-wrapper {
			margin: 0;
			
			a {
				font-size: 1.6rem;
				
				&:first-child {
					img {
						max-width: 10px;
					}
				}
				
				img {
					max-width: 15px;
				}
			}
		}
	}	
} // end 400px height	


/*
@media all and (orientation:landscape) {
	#fp-nav {
		display: none;
	}
	
	#page {
		display: none;
	}
	
	#rotate-screen {
		display: block;
		position: relative;
		
		img {
			width: 150px;
			position: absolute;
			top: 50%;
			margin-top: -64px;
			left: 50%;
			margin-left: -75px;
		}
	}
}
*/