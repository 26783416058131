/*********************************************************************
	RESETS
*********************************************************************/
* {
	margin: 0;
	padding: 0;
}
* {
	.box-sizing();
}
html, html {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	height: 100vh;
	position: relative;

}
html {
	font-size: 62.5%;
	hyphens: auto;
}
body {
	position: relative;
	background-color: #f8f8f8;
	font-family: @euclidFlexRegular;
	font-weight: normal;
	font-size: @fontSizeNormal;
	line-height: @body-line-height;
	
	
	&.prepage {
		margin: 0;
		padding: 0;
		background-color: #000000;
		background-repeat: no-repeat;
		height: 100vh;
		background-size: cover;
		text-align: center;
		
		
		p {
			color: #ffffff;
			padding: 0 15px;
		}

		
		.prepage_content {
			max-width: 960px;
			width: 100%;
			margin: 0 auto;
			
			> p {
				max-width: 550px;
				width: 100%;
				margin: 0 auto;
			}
		}
		
		.prepage_logo {
			padding: 3em 0;
			width: 100%;
			
			> img {
				max-width: 250px;
			}
		}
		
		.prepage_close {
			padding: 25px 0;
		}
	}
}



/*********************************************************************
	DEFAULT TYPOGRAPHY
*********************************************************************/
div, li, p, a, strong, span,
b, u, i,
blockquote,
pre, code,
small {
	line-height: @body-line-height;
	padding: 0;
}
h1
h2,
h3,
fieldset,
fieldset fieldset,
ol, ul, li,
p,
a,
input,
textarea,
header,
footer,
aside,
article {
	text-decoration: none;
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: @euclidFlexMedium;
	font-weight: normal;
	color: @white;
}

p {
	text-align: center;
	line-height: 1.25;
	margin-bottom: 20px;
	
	color: @greyDark;
}

fieldset,
fieldset fieldset,
form {
	border:none;
}
ol,
ul {
	list-style-type: none;
	width: 100%;
	list-style: none;
}
h1 {
	//max-width: @max-width-h1;
	margin: 0 auto 9.5% auto;
	font-size: @headingTitle;
	line-height:1.065;
	text-transform: uppercase;
}
h2 {
	max-width: @max-width-h2;
	margin: 0 auto 25px auto;
	font-size: @subTitle;
	font-family: @euclidFlexRegular;
	font-weight: 400;
	line-height: 1.3;
}


.standartLink {
	padding: 7px 15px;
	width: auto;
	margin: 0 auto;
	display: inline-block;
}


a {
	font-size: @fontSizeNormal;
	text-decoration: none !important;
	color: @primaryColor;
	line-height: 1;
}


.link {
	width: auto;
	margin: 0 auto;
	padding: 7px 15px;
	display: inline-block;
	
	font-size: @fontSizeNormal;
	text-decoration: none !important;
	color: @primaryColor;
	line-height: 1;

	&.link__bordered {
		background: @primaryColor;
		border: 1px solid @primaryColor;
		color: @white;
		transform: translate3d(0,0,0);

		&:hover {
			background: #f68e02;
			border: 1px solid #f68e02;
			transition: all 0.5s ease-in-out;
		}

		+ .link__bordered {
			margin-left: 4px;
		}
	}
	&:focus {
		outline: 0;
	}
}



.uppercase {
	text-transform: uppercase;
}

div,
article {
	display: block;
	border: none;
}
img {
	border: none;
	width: 100%;
}
h1 {
	line-height: 1;
}
input {
	margin: 0;
	padding: 0;
}

iframe {
	width: 100%;
	height: 490px;
	border: none;
}





/***********************************************************************/
// ICONS  
/***********************************************************************/
.icon {
	width: 40px;
	height: 40px;
	display: block;
	background-size: cover;
	margin: 0 auto;
}

.arrow-preset {
	position: absolute;
	z-index: 4;
	bottom: 30px;
	padding: 0;
}

.icon-arrow-down {
	.arrow-preset;
	background-image: url("/site/images/arrow_down.svg");
	background-size: 24px;
	background-repeat: no-repeat;
}

.icon-arrow-up {
	.arrow-preset;
	background-image: url("/site/images/arrow_up.svg");
}

.icon-right {
	background-image: url("/site/images/arrow-small-right.svg");
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 15px 15px;
}

.icon-wrapper {
	width: 25px;
	height: 25px;
	margin: 0 auto;
	opacity: 0;	
}





/***********************************************************************/
// GLOBAL GRADIENTS 
/***********************************************************************/
.gradient-small {
	background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.65) 9%, rgba(0,0,0,0.01) 97%, rgba(0,0,0,0) 99%);
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 9%,rgba(0,0,0,0.01) 97%,rgba(0,0,0,0) 99%);
	background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 9%,rgba(0,0,0,0.01) 97%,rgba(0,0,0,0) 99%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
}






/***********************************************************************/
// HEADER 
/***********************************************************************/
#page {
	margin: 0 auto;
	overflow: hidden;
}

#page-header {
	width: 100%;
	height: 80px;
// 	position: relative;
	display: block;
	position: fixed;
	z-index: 5;
	transition: background-color 0.55s;
	
	
	&.scrolledDown {
		background-color: rgba(74,74,74,1);
	}
}
.header-image {
	position: fixed;
	width: 100%;
	height: 450px;
	top: 0;
	background-color: #4a4a4a;
	display: block;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	z-index: -100;
	transform:translateZ(-100px);
	.animationSetup(0,0,0);
 	.animationSpeed(all, 0s, ease-in-out);	
}

.social-media-wrapper-div {
	position: fixed;
	bottom: 25px;
	left: 22px;
	width: 40px;
	height: 154px;
	background-color: rgba(0,0,0,0.2);
	border-radius: 7px;
	padding: 5px 0;
	transition: all 0.3s ease;
	z-index: 100;

	&:hover {
		background-color: rgba(0,0,0,0.8);
	}

	a {
		padding: 8px;
		width: auto;
		text-align: center;
		display: block;
		line-height: 0;
		transition: all 0.2s ease;
		cursor: pointer;
        opacity: 0.8;

		img {
			height: 20px;
			width: auto;
		}

		&:hover {
          transform: scale(1.2);
          opacity: 1.0;
		}
	}
}




/*********************************************************************
	PAGE CONTENT
*********************************************************************/
.page-content {
	margin: 0 auto;
	width: 100%;
	display: block;
	z-index: 2;
	transform:translateZ(2px);
	position: relative;
	
}


.content {
	display: block;
	position: relative;
	min-height: 80px;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 5px;

	> :first-child {
		padding-top: 65px;
	}
}


.teaser {
	position: relative;
	height: 450px;
	z-index: 1;
	
	
	.teaser-headline {
		height: 450px;
		.gradient-small;
		.gradient-small;
		
		h1 {
			position: relative;
			//width: 50%;
			z-index: 2;
			top: 40%;
			
			font-family: @euclidFlexMedium;
			text-align: center;
		}
	}
}






/*********************************************************************
	SWIPER
*********************************************************************/
.swiper-container {
	width: 100%;
	height: 490px;
}

.content-detail {
	.swiper-container {
		margin-top: 55px;
	}
}

.swiper-button-prev {
	background-image: url("/site/images/arrow_prev.png") !important;
	transition: all .1s ease-in;

	&:hover {
		transform: translateX(-5px);
		transition: all .1s ease-in;
	}
}

.swiper-button-next {
	background-image: url("/site/images/arrow_next.png") !important; 
	transition: all .1s ease-in;

	&:hover {
		transform: translateX(5px);
		transition: all .1s ease-in;
	}
}







/***********************************************************************/
// NAVIGATIONS 
/***********************************************************************/
// Lang Navigation
/*-------------------------------------------------------*/
.main-navigation {
	display: block;
	position: absolute;
	width: 375px;
	height: inherit;
	background-color: #4A4A4A;	
	.animationSetup(100%,0);
	z-index: 12; //3 //5
	right: 0;
	top: 0;
	padding: 10px 0 0 0;
	opacity: 1;
	text-align: right;
	transition: all .4s ease-out;

	
	a {
		color: @white;
		font-size: @leadText;
	}
	
	nav {
		ul {
			li {
				a {
					padding-left: 63px;
				}
				&.active {
					a {
						color: #f5a623;
					}
				}
			}
		}
		
		.nav-first-level {
			border-bottom: 1px solid @grey;
			text-transform: uppercase;
			text-align: left;

			
			li {
				border-top: 1px solid @grey; 
				padding: 15px 0;
				
				&:hover {
					a {
						color: @primaryColor;
					}
				}
			}
			
			.nav-second-level {
				li {
					border-top: none;
					padding: 5px 0;
					
					text-transform: none;
					
					a {
						font-family: @euclidFlexLight;
						color: @white;
					}
					
					&.active {
						a {
							color: #f5a623;
						}
					}
				}
			}
		}
		
		
	}
	&.animate {
		height: 1200px;
		.animationSetup(0,0);
		transition: all .7s ease-out;
		opacity: 1;
		z-index: 11;
	}
}

/***********************************************************************/
// NAVIGATION CONTROL ELEMENTS
/***********************************************************************/
.menu {
	position: absolute;
	right: 0;
	z-index: 5;
	margin: 20px 18px 0 0;
	text-align: right;
	transition: .2s border-color,.2s color;
	
	a {
		.standartLink;
		height: 25px;
		width: 25px;
		padding: 0;
	}
		
	&::before,
	&::after {
		content: "";
		display: block;
		width: 20px;
		height: 1px;
		background-color: @white;
		position: absolute;
		left: 0;
		transition: .5s transform cubic-bezier(.23,1,.32,1),.4s box-shadow cubic-bezier(.23,1,.32,1);
	}
	
	
	&::before {
		top: 2px;
		box-shadow: 0 5px 0 @white;
	}
	
	&::after {
		top: 12px;
	}
	
	&:hover:before {
		transform: translateY(-2px);
		box-shadow: 0 7px 0 @white;
	}
	
	&:hover:after {
		transform: translateY(2px);
	}
}

.close {
	font-weight: bold;
	border: none;
	padding: 20px;
	transition: all .4s ease-out;
	.standartLink;
}




/*********************************************************************
	LANGUAGE NAVIGATION
*********************************************************************/
.lang-nav {
	display: none;
	width: 100%;
	position: absolute;
	margin-top: 25px;
	
	text-align: center;

	ul {
		li {
			display: inline-block;
		
			&:first-child:after {
				content: "|";
				padding: 0 .6em 0 1em;
				
				color: @white;
			}
			
			
			a {
				padding: 0 0 5px;
				.animationSetup(0);
				.animationSpeed(all, 0.1s);
				
				text-transform: uppercase;
				color: @white;
				
				&:hover {
					padding: 0 0 2px;
					border-bottom: 2px solid @white;
				}
				
				&.active {
					padding: 0 0 2px;
					border-bottom: 2px solid @white;
				}
			}
		}
	}
	
	// Mobile Lang Nav
	&.mobile {
		text-align: left;
		padding-left: 65px;
		margin-top: 0px;
		display: none;
	}
}


/*********************************************************************
	ARTICLE NAVIGATION
*********************************************************************/
.nav-article {
	display: table;
	max-width: 789px;
	width: 100%;
	margin: 0 auto;
	text-align: center;

	a {
		color: @greyDark;
		padding: 0;
		text-transform: uppercase;
	}
}

.nav-article-back {
	.standartLink;
	width: 58%;
	text-align: center;
}

.icon-prev-grey,
.icon-next-grey,
.icon-article-back {
	display: inline-block;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	transition: all .1s ease-in;
}

.icon-prev-grey,
.icon-next-grey  {
	width: 16px;
	height: 17px;
}


.icon-article-back {
	width: 20px;
	height: 20px;
	background-image: url('/site/images/icon_back_to_overview.svg');
}

.icon-prev-grey {
	background-image: url('/site/images/arrow_prev_grey.svg');
	margin: 0 17px 0 0;
}

.icon-next-grey {
	background-image: url('/site/images/arrow_next_grey.svg');	
	margin: 0 0 0 17px;
}

.nav-article-prev,
.nav-article-next {
	.standartLink;
	width: 20%;
}

.nav-article-prev {
	text-align: left;
	
	&:hover {
		.icon-prev-grey	{
			transform: translateX(-10px);
			transition: all .1s ease-in;
		}
	}
}

.nav-article-next  {
	text-align: right;
	
	&:hover {
		.icon-next-grey	{
			transform: translateX(10px);
			transition: all .1s ease-in;
		}
	}
}





/*********************************************************************
	LOGO
*********************************************************************/
#logo {
	position: absolute;
	z-index: 5;
	
	a {
		width: 248px;
		height: 100%;
		margin: 5px 0 0 18px;
		.standartLink;
	}
	
	img {
		width: 100%;
	}
}




/***********************************************************************/
// LANDINGPAGE 
/***********************************************************************/
/* Centered texts in each section
* --------------------------------------- */
.section{
	overflow: hidden;
	text-align:center;	

	span {
		width: 30px;
		height: 30px;
		position: absolute;
		bottom: 20px;
		z-index: 4;
	}
}

.link-wrapper {
	display: inline-block;
}

.backgroundSettings {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center !important;
}

.positionFixBottom {
	p {
		position: absolute;
		left: 0;
		bottom: 100px;
		width: 100%;
	}
	
	h2 {
		width: 100%;
		position: absolute;
		bottom: 160px;
	}
}

#rotate-screen {
	width: 100%;
	height: 100%;
	z-index: 200;
	background-color: rgb(74, 74, 74);
	display: none;
	
	img {
		width: 100px;
		left: 50%;
		top: 50%;
		position: relative;
		margin-left: -50px;
		margin-top: -43px;
	}
}


#fullpage {

	video {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		//width: 100%;
		height: 100%;
		min-width: 100%;
		min-height: 100%;
		background-size: 100% 100%;
		background-color: black;
		background-position: center center;
		background-size: contain;
		object-fit: cover;
	}

	.text-area {
		max-width: 800px;
		padding: 37px 0;
		
		text-align: center;
		
		h2 {
			color: @white;
			font-size: @subTitle;
		}
	}
}



// REFERENCES BUTTONS
//-----------------------------------
.link-wrapper-references {
	width: 100%;
	margin: 28px auto 0 auto;

	div {
		display: inline-block;
		width: auto;
		text-align: center;

		a {
			display: inline-block;
			position: relative;
			float: left;
			width: 147px;
		}
	}

	&:after {
		.clearfix();
	}
}


// REFERENCES DATA WRAPPER
//-----------------------------------
.references-data {
	padding-top: 70px;
	
	text-align: center;
	
	h2 {
		padding-bottom: 38px;
		color: #4A4A4A;
	}
	
	p {
		padding-bottom: 65px;
	}
}






// ANIMATION SETUP
//-----------------------------------
.landingPageAnimationFromBottom {
	transform: translate3d(0, 150px, 0);
	opacity: 0;
	transition: all .7s ease-out;
}

.landingPageAnimationFromTop {
	transform: translate3d(0, -150px, 0);
	opacity: 0;
	transition: all .7s ease-out;
}


.landingPageAnimationReset {
	transform: translate3d(0, 0px, 0);
	opacity: 1;
	transition: all .7s ease-out;
}

.transitionSmooth {
	transition: all 0.8s ease-out;
}



/* Backgrounds will cover all the section
* --------------------------------------- */
#section0,
#section1,
#section2,
#section6{	
	.backgroundSettings;
	.positionFixBottom;
}


/* Defining each sectino background and styles
* --------------------------------------- */
#section0 {
	h1 {
		width: 90%;
		transform: translate3d(0, -150px, 0);
		opacity: 0;
		transition: all .4s ease-out;
	}
	
	p {
		transform: translate3d(0, 150px, 0);
		opacity: 0;
		transition: all .4s ease-out;
	}
	
	&.fp-completely {
		h1,
		p {
			transform: translate3d(0, 0px, 0);
			opacity: 1;
			transition: all .7s ease-out;
		}
		
		h1 {}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 1s ease-in-out;
		}
	}
	
	.text-area {
		margin-top: 12.9%;
	}
}

#section1 {
	h1 {
		.transitionSmooth;
		.landingPageAnimationFromTop;
	}
	
	h2,
	p {
		.transitionSmooth;
		.landingPageAnimationFromBottom;
	}
	
	&.active {
		h1,
		h2,
		p {
			.landingPageAnimationReset;
		}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 1.1s ease-in;
		}
	}
	
	.text-area {
		margin-top: 9%;
	}
}

#section2 {
	h1 {
		.transitionSmooth;
		.landingPageAnimationFromTop;
	}
	
	h2,
	p {
		.transitionSmooth;
		.landingPageAnimationFromBottom;
	}
	
	&.active {
		h1,
		h2,
		p {
			.landingPageAnimationReset;
		}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 1.1s ease-in;
		}
	}
	
	.text-area {
		margin-top: 1.9%;
	}
	
	h1 {
		margin-bottom: 0;
	}
	
	h2 {
		padding-top: 110px;
	}
	
	article {
		margin-top: 21.3%;
	}
}

#section3 {
	.backgroundSettings;

	h1 {
		.transitionSmooth;
		.landingPageAnimationFromTop;
	}
	
	h2,
	.link-wrapper-references {
		.transitionSmooth;
		.landingPageAnimationFromBottom;
	}
	
	&.active {
		h1,
		h2,
		.link-wrapper-references {
			.landingPageAnimationReset;
		}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 1.1s ease-in;
		}
		
		.fp-controlArrow {
			&.fp-prev {
				transform: translate3d(0px,0,0);
				opacity: 1;
				transition: all .7s ease-out;
				
				&:hover {
					transform: translate3d(-10px,0,0);
				}
			}
			
			&.fp-next{
				transform: translate3d(0px,0,0);
				opacity: 1;
				transition: all .7s ease-out;
		
				&:hover {
					transform: translate3d(10px,0,0);
				}
			}
		}
	}

	h2 {
		margin-bottom: 0;
	}
	
	.text-area {
		margin-top: 1.9%;
	}
	
	article {		
		h1 {
			display: none;
			margin-bottom: 4.5%;
			padding-bottom: 250px;
		}
	}
	
	.fp-controlArrow {
		&.fp-prev {
			transform: translate3d(120px,0,0);
			opacity: 0;
			transition: all .7s ease-out;
		}
		
		&.fp-next{
			transform: translate3d(-120px,0,0);
			opacity: 0;
			transition: all .7s ease-out;
		}

	}
}

#section4 {
	background: #bebebe ; /* Old browsers */
	background: -moz-linear-gradient(top,  #bebebe  0%, #efefef  100%); /* FF@subTitle-15 */
	background: -webkit-linear-gradient(top,  #bebebe  0%,#efefef  100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #bebebe  0%,#efefef  100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bebebe ', endColorstr='#efefef ',GradientType=0 ); /* IE6-9 */
	
	#newsblock {
		padding: 0;
	
		.news {
			h2 {
				opacity: 0;
				transform: translate3d(-120px,0,0);
				.transitionSmooth;

			}
			span {
				opacity: 0;
				transform: translate3d(70px,0,0);
				.transitionSmooth;
			}
		}
	}
	
	&.active {
		#newsblock {
			.news {
				h2 {
					transform: translate3d(0px,0,0);
					opacity: 1;
					.transitionSmooth;
				}
				span {
					opacity: 1;
					transform: translate3d(0px,0,0);
					.transitionSmooth;
					transition: all .1s ease-out;
				}
				&:hover {
					span {
						transform: translateX(10px);
						transition: all .1s ease-in;
					}
				}
			}
		}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 0.1s ease-in;
		}
	}
}

#section5 {
	h1 {
		font-size: @subTitle;
		margin-bottom: 22px;
		.landingPageAnimationFromTop;
	}
	
	.text-area {
		padding: 0;
	}
	
	.footer-wrapper-disclaimer {
		.landingPageAnimationFromBottom;
	}
	
	&.active {
		h1 {
			.landingPageAnimationReset;
		}
		
		.footer-wrapper-disclaimer {
			.landingPageAnimationReset;
		}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 1.1s ease-in;
		}
	}
}

#section6 {
	h1 {
		.transitionSmooth;
		.landingPageAnimationFromTop;
	}
	
	h2,
	p {
		.transitionSmooth;
		.landingPageAnimationFromBottom;
	}
	
	&.active {
		h1,
		h2,
		p {
			.landingPageAnimationReset;
		}
		
		.icon-wrapper {
			opacity: 1;
			transition: opacity 1.1s ease-in;
		}
	}
	
	.text-area {
		margin-top: 9%;
	}
}

/*Adding background for the slides
* --------------------------------------- */
#slide1,
#slide2,
#slide3 {
	.backgroundSettings;
	
	.fp-tableCell {
		
		h1 {
			margin: 0 auto 3%;
		}
		article {
			position: absolute;
			bottom: 80px;
			text-align: center;
			margin: 0 auto;
			width: 100%/3;
		}
	} 	
}




/* Pagination Fullpage 
* --------------------------------------- */
#fp-nav {
	width: 52px;
	min-height: 185px;
	.animationSpeed(all, 0.1s);
	
	&.hide {
		opacity: 0;
	}
	
	ul {
		li {
			width: 15px;
			height: 15px;
			
			&:hover {
				a span,
				a.active span {
						width: 15px;
						height: 15px;
						margin: -2px 0 0 -2px;
						background-color: @white;
						border: 1px solid @white;	
						opacity: 0.5;
				}
			}	
			
			a {
				&.active {
					span {
						width: 15px;
						height: 15px;
						margin: -2px 0 0 -2px;	
						border: 1px solid @white;	
						background-color: @white;
					}
				}
				
				span {
					width: 15px;
					height: 15px;
					background-color: transparent;
					border: 1px solid @white;	
				}
			}
			
			.fp-tooltip.right {
				right: 30px;
				top: 10px;
			}
		}
	}
}

.fp-controlArrow {
	top: 50%;
	
	
	&.fp-prev,
	&.fp-next {
		width: 42px;
		height: 40px;
		border: none;
		transition: all .1s ease-in;
	}
	
	&.fp-prev {
		left: 14%;
		background-image: url("/site/images/arrow_prev.png");
		
		&:hover {
			transform: translateX(-10px);
			transition: all .1s ease-in;
		}
	}
	
	&.fp-next {
		right: 14%;
		background-image: url("/site/images/arrow_next.png");
		
		&:hover {
			transform: translateX(10px);
			transition: all .1s ease-in;
		}
	}
}


.frontpage-news {
	h1 {
		font-size: @subTitle;
		color: @greyDark;
		margin: 0 auto;
	}
}



/***********************************************************************/
// CONTENT 
/***********************************************************************/
.page-content {
	background-color: #f8f8f8;
	transition: all .4s ease-out;
}

.content-detail {
	max-width: 789px;
	width: 100%;
	margin: 0 auto 33px;
	padding: 60px 0 20px 0px;
	border-bottom: 1px solid @grey;
	
	h2 {
		margin: 0;
		padding: 0;
		font-size: @leadText;
		color: @greyDark;
	}
	
	p {
		text-align: left;
		margin-bottom: 20px;
		color: @greyDark;
	}

	
	span {
		display: block;
		
		line-height: 1.25;
		font-size: @fontSizeNormal;
		color: #9B9B9B;
		
		&.service-category {
			margin-bottom: 20px;
						
			a {
				padding: 0
			}
		}
	}
	
	ul {
		li {
			list-style: initial;
			 margin-left: 40px;
		}
	}
}

.detail-link {
	max-width: 789px;
	margin: 0 auto 18px auto;
	.clearfix();	
	
	&:last-child {
		padding: 0 0 30px 0;
		border-bottom: 1px solid @grey;
	}
	a {
		text-align: left;
		padding: 0;
		display: block;
		float: left;
		transform: translate3d(0,0,0);

		&:hover {
			color: #f68e02;
			transition: color 0.5s ease-in-out;
		}
	}
	.icon-right {
		display: block;
		height: 21px;
		background-position: center 1px;
		float: left;
	}
}

// ---------------------------------------------------------------------------------------------------------------------

.title-block,
.text-block,
.image-block,
.video-block,
.button-block,
.swiper-block {
	max-width: 800px;
	margin: 0 auto;
}

.title-block {
	margin-top: 25px;
	margin-bottom: 25px;

	&.left {
		text-align: left;
	}

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.mtop {
		margin-top: 65px;
	}

	&.mbot {
		margin-bottom: 65px;
	}

	h2 {
		margin: 0;
		padding: 0;
		color: @greyDark;
		font-family: @euclidFlexRegular;
		font-size: @leadText;
		max-width: 100%;

		&.white {
			color: @white;
		}
	}

	h3 {
		margin: 0;
		padding: 0;
		color: #9B9B9B;
		font-family: @euclidFlexRegular;
		font-size: @fontSizeNormal;
		line-height: 1.25;

		&.white {
			color: @white;
		}
	}
}

.text-block {
	margin-top: 25px;
	margin-bottom: 25px;

	&.left {
		p {
			text-align: left;
		}
	}

	&.center {
		p {
			text-align: center;
		}
	}

	&.right {
		p {
			text-align: right;
		}
	}

	&.mtop {
		margin-top: 65px;
	}

	&.mbot {
		margin-bottom: 65px;
	}

	p {
		font-family: @euclidFlexRegular;
		line-height: 1.25;
		color: @greyDark;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&.white {
		p {
			color: @white;
		}
	}
}

.image-block {
	font-size: 0;
	line-height: 0;
	margin-top: 25px;
	margin-bottom: 25px;

	&.mtop {
		margin-top: 65px;
	}

	&.mbot {
		margin-bottom: 65px;
	}

	a {
		font-size: 0;
		line-height: 0;
	}

	img {
		width: 100%;
	}
}

.video-block {
	font-size: 0;
	line-height: 0;
	margin-top: 25px;
	margin-bottom: 25px;

	&.mtop {
		margin-top: 65px;
	}

	&.mbot {
		margin-bottom: 65px;
	}

	.video-wrapper {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.button-block {
	margin-top: 25px;
	margin-bottom: 25px;

	&.left {
		text-align: left;
	}

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.mtop {
		margin-top: 65px;
	}

	&.mbot {
		margin-bottom: 65px;
	}
}

.swiper-block {
	margin-top: 25px;
	margin-bottom: 25px;

	&.mtop {
		margin-top: 65px;
	}

	&.mbot {
		margin-bottom: 65px;
	}

	.swiper-container {
		margin-top: 25px;
	}
}

// ---------------------------------------------------------------------------------------------------------------------

.text-area {
	max-width: 800px;
	margin: 0 auto;
	padding: 65px 0 0 0;
	
	text-align: center;
	
	h2 {
		color: @greyDark;
		font-size: @leadText;
	}
	
	&.job-description {
		p {
			text-align: left;
			padding: 0;
			
			&:last-child {
				margin-top: 76px;
				text-align: center;
			}
		}
	}
	
	&.impressum {
		p {
			text-align: left;
		}
		
		a {
			padding: 0;
		}
	}
}

.hoverAnimation {
	background-color: rgba(0, 0, 0, 0.5);
}

.box-content-wrapper {
	display: table-cell;
	text-align: center;
	vertical-align: middle;

	&.aboutus {
		position: relative;
	}
}



.detail-link-title {
	position: absolute;
	z-index: 10;
	left: 0;
	width: 100%;
	color: @white;
	font-size: @subTitle;
	text-align: center;
}






/*********************************************************************
	DETAIL VIEWS
*********************************************************************/
.box-wrapper {
	position: relative;
	width: 50%;
	float: left;
	padding: 5px;
	transition: all .7s ease-in;
	opacity: 0;
	
	&.visible {
		opacity: 1;
		transition: all .7s ease-in;
	}
}


.view-overview-small {
	width: 100%;
	text-align: center;
	display: inline-block;
	margin-top: 45px;
	
	a {
		width: 100%;
		display: table;
		float: left;
		position: relative;
		padding: 0;
		line-height: 0;	
		background-color: @black;
		overflow: hidden;
		
		&.link {
			&.link__bordered {
				padding: 7px 15px;
				margin-bottom: 10px;
				line-height: 1;
			}
		}
		
		.detail-link-title {
			width: 100%;
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s, opacity 0.1s ease-in;
			font-size: @leadText;
			margin-top: -10px;
			line-height: 1.2;
		}
		
		img {
			transform: scale(1.050);
			transition: all .3s ease-in;
		}
		
		&:hover {
			img {
				opacity: 0.5;
				transform: scale(1);
			}
			
			.detail-link-title {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}


.view-overview-big {
	width: 100%;
	display: inline-block;
	text-align: center;
	margin-top: 45px;
	
	
	a {
		width: 100%;
		display: table;
		position: relative;
		background-color: @black;
		padding: 0;
		overflow: hidden;
		
		span {
			line-height: 0 !important;
		}

		img {
			transform: scale(1.050);
			transition: all .3s ease-in;
		}
		
		
		&:hover {
			img {
				opacity: 0.5;
				transform: scale(1);
			}
		}
	}
}



/*********************************************************************
	TEAM
*********************************************************************/
.orangebox {
	width: 100%;
	height: 390px;
	display: table;
	background-color: #f5a623;
	overflow: hidden;
	
	.department-title {
		position: absolute;
		display: block;
		z-index: 10;
		width: 100%;
		line-height: 0;
		
		color: @white;
		font-size: @leadText;
	}
}



.team-item {
	position: relative;
	width: 100%;
	height: 390px;
	display: table;
	overflow: hidden;
	background-color: @black;
	
	color: @white;
	line-height: 1;
	opacity: 0;
	
	&.visible {
		opacity: 1;
		transition: all .7s ease-in;
	}

	
	span, a {
		display: inline-block;
		width: 100%;
		height: auto;
		z-index: 9;
		
		font-size: @leadText;
	}
	
	a {
		line-height: 1;
	}
	
	.position {
		padding: 0 30px 20px 30px;
	}
	
	
	.tel {
		max-width: 100%;
		background-color: transparent;

		font-size: @fontSizeNormal;
		color: #F5A623;
		
		&.visible {
			transition: all 0s ease-in;
		}
	}
	
	.mail {
		margin-top: 10px;
		max-width: 100%;
		height: 40px;
		padding-top: 8px;
		background-color: transparent;

		
		.mail-icon {
			width: 40px;
			height: 27px;
		}
		
		&.visible {
			transition: all 0s ease-in;
		}
	}
	
	.portrait-image {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;

		background-position: center center;
		background-size: cover;
		width: 100%;
		height: 100%;
		transform: scale(1.025);
		transition: all .3s ease-in;
	}
	
	
	
	 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
		/* IE10+ specific styles go here */
		
		.portrait-image {
			height: 235%;
		}
	}
	
	
	&:hover {
		.portrait-image {
			opacity: 0.2;
			transform: scale(1);
		}
		
		a {
			z-index: 11;
			
			img {
				transform: scale(1.0);
				opacity: 1;
			}
		}
	}
}


.references-link {
	padding-bottom: 0;
	text-align: center;
}



/*********************************************************************
	NEWS
*********************************************************************/
#newsblock {
	padding: 0;
	display: inline-block;

	.news {
		transform: translate3d(-35px,0,0);
		border-bottom: 1px solid @greyDark;
		visibility: hidden;
		
		&.visible {
			visibility: visible;
			transition: all .3s ease;
			transform: translate3d(0,0px,0);
		}
		
		h2 {
			width: 97%;
			display: inline-block;
			max-width: initial;
			margin: 0;
			padding: 26px 0 26px 10px;

			font-size: @fontSizeNormal;
			text-align: left;
			line-height: initial;
			color: @greyDark;
		}
		
		a {
			width: 100%;
		}
		
		span {
			width: 20%;
			vertical-align: top;
			margin-top: 30px;
			bottom: initial;
			transition: all .1s ease-in;
						
			&.icon {
				width: 20px;
				height: 17px;
				display: inline-block;
			}
		}
		
		&:hover {
			.icon-right {
				transform: translateX(10px);
				transition: all .1s ease-in;
			}
		}
	}
	
	li:first-child {
		border-top: 1px solid @greyDark;
	}
}

.readmore-wrapper {
	width: 100%;
	
	text-align: center;
}

#btnMore {
	margin-top: 65px;
}



.view-list {
	max-width: 800px;
	margin: 25px auto;
}

.last-article {
	width: 100%;
	height: 60px;
	display: inline-block;
	padding-bottom: 85px;
	
	
	a {
		margin: 0 auto;
		position: relative;
		top: 28px;
		
		text-align: center;
		
		&.icon {
			width: 30px;
			height: 30px;
		}
	}
}




/***********************************************************************/
// FORMULARE 
/***********************************************************************/
 #rex-yform {
	max-width: 350px;
	margin: 0 auto;
	height: auto;
	
	
	&.form-done {
		height: 200px;
	}


	.hOneStyle {
		margin: 30px 0;
		
		color: @greyDark;
		font-size: @subTitle;
		text-align: center;
	}
	
	
	h1 {
		.hOneStyle;
	}
	
	p {
		font-size: 16px;
		margin-bottom: 0;
	}
	
	form {
		padding-bottom: 30px;
		
		color: #555555;
		
		input, 
		select,
		textarea {
			height: 50px;
			padding: 5px;
			width: 100%;
			display: inline-block;
			margin-bottom: 20px;
			background-color: #EEEEEE;
			border: 1px solid #9B9B9B;
			border-radius: 2px;
			cursor: pointer;
			font-size: @formPlaceholder;
			outline: none;
			color: #9B9B9B;
			
			&.form_warning {
				border: 1px solid @red;
			}
		}
		
		fieldset {
			p {
				text-align: left;
			}
		}
		
		input {
			line-height: 1.1;
			
			&.submit {
				height: 35px;
			}
		
			&.error {
				border: 1px solid @red;
			}
		}
		
		input::-webkit-input-placeholder { 
			padding: 0 0 0 5px;
			color: #9B9B9B;

		}
		input:-moz-placeholder { 
			padding-left: 5px;
			
			color: #9B9B9B;
		}
		input::-moz-placeholder {
			padding-left: 5px;
			
			color: #9B9B9B;
		}
		input:-ms-input-placeholder {
			padding-left: 5px;
			
			color: #9B9B9B;
		}
		textarea::-webkit-input-placeholder { 
			padding: 5px 0 0 5px;
			
			color: #9B9B9B;
			font-family: @euclidFlexRegular;
		}
		textarea:-moz-placeholder { 
			padding: 5px 0 0 5px;
			
			color: #9B9B9B;
			font-family: @euclidFlexRegular;
		}
		textarea::-moz-placeholder {
			padding: 5px 0 0 5px;
			
			color: #9B9B9B;
			font-family: @euclidFlexRegular;
		}
		textarea:-ms-input-placeholder {
			padding: 5px 0 0 5px;
			
			font-family: @euclidFlexRegular;
			color: #9B9B9B;

		}
		textarea {
			height: 100px;
			
			font-family: @euclidFlexRegular;
			
			&.error {
				border: 1px solid @red;
			}
		}
		
		label {
			&.error {
				display: none !important;
			}
		}
		
		select {
			width: 50%;
			padding-left: 10px;
			appearance: none;
			background-image: url("/site/images/select_arrow_down.png");
			background-position: 90% 22px;
			background-repeat: no-repeat;
		
			&.error {
				border: 1px solid @red;
			}
		}
		
		// IE 10 appearance none fallback	
		select::-ms-expand {
			display: none;
		}
		
		.formcheckbox {
			display: block;
			margin-bottom: 5px;
			
			.checkbox {
				font-size: 1.4rem;
				margin: 0 15px 0 0;
			}
		}
		
		input[type='checkbox'],
		input[type='radio'] {
			width: auto;
			width: initial;
			
			height: auto;
			height: initial;
			margin: 0;
		}

		
		.formsubmit {
			text-align: center;
			padding-top: 50px;
		}

		
		.loader-wrapper {
			width: 100%;
			text-align: center;
			margin-top: 20px;
			
			.loading-icon {
				width: 16px;
				height: 16px;
				opacity: 0;
				display: none;
			}
		}
		
		.formradio {
			margin-top: 25px;
		}
		
		.radios {
			margin: 5px 0 35px 0;
			
			.radio {
				
				input {
					margin: 0 15px 0 0;
				}
				
				label {
					font-size: 1.4rem;
					margin: 0 15px 0 0;
				}
			}
		}

		.require-info {
			max-width: 350px;
			text-align: left;
			width: 100%;
			font-size: @fontSizeSmall;
		}
		
		.freelancer-form {
			.job-title {
				margin-bottom: 5px;
			}
		}
		
	}	
	
	ul.form_warning {
		display: none;
		width: 55%;
		margin: 0 auto;
		
		color: @red;
		text-align: left;
	}
}

.loader-wrapper {
	width: 100%;
	text-align: center;
	margin-top: 20px;
	
	.loading-icon {
		width: 16px;
		height: 16px;
		opacity: 0;
	}
}




/*********************************************************************
	MAGNIFIC POPUP
*********************************************************************/
.mfp-bg {
	&.mfp-ready {
		min-height: 2000px !important;
	}
}

.mfp-content {
	#page-header,
	.teaser,
	.footer-contet-wrapper {
		display: none;
	}
	
	 #rex-yform;
	
	.newsletter-form {
		background-color: @white;
		margin: 10px 0px;
		
		h1 {
			.hOneStyle;
			margin: 55px 0 35px 0;
		}
		
		#mc_embed_signup_scroll {
			max-width: 350px;
			margin: 0 auto;
		}
		
		.submit-button {
			text-align: center;
			padding-top: 20px;
		}
		
		.form-thx-message {
			position: absolute;
			top: 240px;
			margin: 0 auto;
			width: 100%;
			text-align: center;
		}
	}
	
	.contact-form {
		h1 {
			margin: 55px 0 35px 0;
		}
		
		fieldset:first-child {
			margin-bottom: 35px;
			display: none
		}
	}
}

.mfp-wrap {
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0);
}

html.mfp-helper {
  height: 100%;
  width: 100%;

  body {
    overflow: hidden;
    height: 100%;
    -webkit-transform: translateZ(0);
  }
}

.form-thx-message {
	display: none;
	opacity: 0;
	text-align: center;
}

.popup_freelancer {
	.link__bordered {
		margin: 0 auto;
	}
}





/***********************************************************************/
// FOOTER 
/***********************************************************************/
.footer-wrapper {
	position: relative;
	height: 70px;
	min-height: 100%;
	max-width: 1020px;
	margin: 40px auto 0px;

}

.content-footer {
	//position: relative;
	width: 100%;
	padding: 40px 0;
	background-color: rgb(74, 74, 74);
	z-index: 12;
	text-align: center;
	
	.content-footer-title {
		margin: 0 auto;
		padding-bottom: 30px;
		
		font-size: @subTitle;
	}
}

#section5,
.content-footer {
	h1 {
		font-size: @subTitle;
	}

	p,
	a {
		font-size: @fontSizeNormal;
		color: @white;
	}
	
	p,
	a[href^=tel] {
		margin-top: 22px;
		line-height: 1.33;
	}
	
	a {
		margin-top: 0;
		color: @primaryColor;
	}
	
	.social-media-wrapper {
		margin: 22px 0;
		
		a {
			.standartLink;
		}
	}
	
	.link__bordered {
		width: 230px;
		margin-bottom: 14px;
		display: inline-block;
		color: @white;
	}
	a[href^=tel],
	a[href^=mailto] {
		display: block;
		padding: 0;
		
		color: @white;
	}
	.footer-wrapper-disclaimer {
		width: 540px;
		margin: 49px auto 88px auto;
		border-top: 1px solid #999;
		
		.disclaimer-left,
		.disclaimer-right {
			width: 50%;
			float: left;
			
			a {
				.standartLink;
			}
			
			img {
				margin: 8px 0 0 0;
			}
			
			p,
			a {
				font-size: @fontSizeSmaller;
				line-height: 1.25;
			}	
		}
		.disclaimer-right {
			img {
				width: 113px;
				margin: 0;
			}
			a,
			p {
				padding: 0;
				margin-bottom: 11px;
			}
		}
	}
}





/***********************************************************************/
// Specials 
/***********************************************************************/
.page-menu-lang {
	display: none;
}

.icon {				
	&.menu-open,
	&.menu-close {
		background-image: url('/site/images/open_navigation.png');
		background-repeat: no-repeat;
		display: inline-block;
		width: 20px;
		height: 20px;
	}
	&.menu-close {	
		background-image: url('/site/images/close_white.png');		
		margin-right: 8px;
	}
	&.arrow-right {
		display: inline-block;
		height: 30px;
		width: 65px;
		float: right;
		
		background-image: url('/site/images/arrow-right.svg');
		background-repeat: no-repeat;	
		background-position: -11px 7px;
		background-size: contain;
		
		.animationSetup(0);
		.animationSpeed(all, 0.1s);
	}
}



#recaptcha-contact,
#recaptcha-freelancer,
#recaptcha-newsletter {
	display: none;
}


#mce-error-response,
#mce-success-response {
	font-size: @fontSizeSmall;
	
	a {
		font-size: @fontSizeSmall;
	}
	
	p {
		max-width: 350px;
		text-align: left;
		margin-top: 15px;
		font-size: @fontSizeSmall;
		text-align: left;
	}
}

#mce-responses {
	//margin-top: 40px;
	color: @red;
}

#mce-success-response {
	opacity: 0;
}

.fp-slide {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center!important;
}

.cc-window {
	z-index: 10000;
	padding: 0 !important;
	-ms-flex-negative: 1;

	.cc-message {
		font-family: 'Euclid Flex Regular';
		font-size: 14px;
		margin: 15px 0 15px 15px !important;
		-ms-flex-negative: 1;
		white-space: normal;

		a {
			font-family: 'Euclid Flex Regular';
			font-size: 14px;
			color: #f5a623 !important;
			text-decoration: underline !important;
		}
	}

	.cc-compliance {
		margin: 15px;
		-ms-flex-negative: 1;

		a {
			font-family: 'Euclid Flex Regular';
			font-weight: 400;
			width: auto;
			margin: 0 auto;
			padding: 7px 15px !important;
			display: inline-block;
			font-size: 1.8rem;
			text-decoration: none !important;
			color: #f5a623;
			line-height: 1;

			background: #f5a623;
			border: 1px solid #f5a623;
			color: #fff;
			-webkit-transform: translate3d(0,0,0);
			transform: translate3d(0,0,0);
			min-width: 190px !important;

			&:hover {
				background: #f68e02;
				border: 1px solid #f68e02;
				transition: all .5s ease-in-out;
			}
		}
	}
}